import React from "react";
import { Typography, ListItem, ListItemText } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { Link as ScrollLink } from "react-scroll";

function FooterLink({ to, title, width, externalLink }) {
  const handleClick = () => {
    console.log(externalLink);
    
    if (externalLink) {
      window.open(externalLink, "_blank");
    }
  };

  return (
    <ListItem>
      <ListItemText align={isWidthUp("lg", width) ? "start" : "center"}>
        <Typography variant="subtitle1">
          {externalLink ? (
            <span
              onClick={handleClick}
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "inherit",
              }}
            >
              {title}
            </span>
          ) : to ? (
            <ScrollLink
              to={to}
              spy={true}
              smooth={true}
              duration={500}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {title}
            </ScrollLink>
          ) : (
            title
          )}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}

export default withWidth()(FooterLink);
